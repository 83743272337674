<template>
  <div class="compoWrap">
    <el-form ref="form" :model="form" :inline="true" label-position="top">
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-input v-model="form.sex"></el-input>
      </el-form-item>
      <el-form-item label="出生日期">
        <el-input v-model="form.birthday"></el-input>
      </el-form-item>
      <el-form-item label="婚姻状况">
        <el-input v-model="form.marriageName"></el-input>
      </el-form-item>
      <el-form-item label="身份证号">
        <el-input v-model="form.idcard"></el-input>
      </el-form-item>
      <el-form-item label="民族">
        <el-input v-model="form.nationName"></el-input>
      </el-form-item>
      <el-form-item label="身高(CM)">
        <el-input v-model="form.height"></el-input>
      </el-form-item>
      <el-form-item label="体重(KG)">
        <el-input v-model="form.weight"></el-input>
      </el-form-item>
      <el-form-item label="文化程度">
        <el-input v-model="form.educationDictName"></el-input>
      </el-form-item>
      <el-form-item label="家庭住址">
        <el-input :value="address"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="E-mail">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="工作单位">
        <el-input v-model="form.workUnit"></el-input>
      </el-form-item>
      <el-form-item label="职业">
        <el-input v-model="form.occupationDictName"></el-input>
      </el-form-item>
      <el-form-item label="医保类型">
        <el-input v-model="form.medinsTypeDictName"></el-input>
      </el-form-item>
      <el-form-item label="血型">
        <el-input v-model="form.bloodDict"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryBase } from '@/api/healthRecord';
const { province_list, city_list, county_list } =
  require('@/utils/area').default;
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    address: function () {
      return [
        province_list[this.form.resProvice] || '',
        city_list[~~this.form.resCity] || '',
        county_list[~~this.form.resDistrict] || '',
      ]
        .filter((i) => i)
        .join('/');
    },
  },
  data() {
    return {
      form: {
        // address: [
        //     province_list[~~this.form?.resProvice] || '',
        //     city_list[~~this.form?.resCity] || '',
        //     county_list[~~this.form?.resDistrict] || '',
        // ]
        //     .filter((i) => i)
        //     .join('/'),
        birthday: null,
        bloodDict: null, // ABO血型代码
        educationDictCode: null, // 文化程度，1标识小学以下、4标识中专及技校、5标识大学本科、6标识大学专科、7标识研究生及以上、99标识其他
        educationDictName: null, // 文化程度 描述
        email: null,
        height: null,
        idcard: null,
        marriage: null, // 婚姻code，1标识未婚，2标识已婚，3标识离异，4标识丧偶
        marriageName: null, // 婚姻名
        medinsType: null,
        medinsTypeDictCode: null, // medinsTypeDictCode：医保类型，1标识城镇居民基本医疗保险、2标识城镇职工基本医疗保险、3标识新型农村合作医疗、4标识自费、5标识其他   （dict.medicalInsurance.type）
        medinsTypeDictName: null, // 医保类型
        mobile: null,
        name: '',
        nation: null,
        nationName: null,
        occupationDictCode: null,
        occupationDictName: null, // 职业
        sex: null, // 性别 1男，2女
        weight: null,
        workUnit: null, // 工作单位
        resProvice: '',
        resCity: '',
        resDistrict: '',
      },
    };
  },
  created() {
    const params = {
      userId: this.userId,
    };
    queryBase(params).then((res) => {
      const { data } = res;
      this.form = data || {};
    });
  },
};
</script>

<style lang="scss" scoped>
.compoWrap {
  padding: 20px;
  width: 100%;
  height: 90vh;
  background-color: #fff;
}
</style>
